import React from 'react';
import styled from '@emotion/styled';
import Toolbar from '../../components/ToolBar/Toolbar';
import Footer from '../../components/Footer/Footer';

const TermWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
`;

const TermContainer = styled.div`
  display: flex;
  width: 60%;
  height: auto;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 5%;

  .heading {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .description {
    margin-bottom: 35px;
  }

  @media (max-width: 496px) {
    width: 80%;
    margin-top: 10%;
  }
`;

const Term = () => {
  return (
    <>
      <Toolbar />
      <TermWrapper>
        <TermContainer>
          <p className="heading">Terms and Conditions</p>
          <p className="title">Terms</p>
          <p className="description">
            This website (“MidhaPro.com”) is owned and operated by ----------.
            By accessing this web site, you are agreeing to be bound by these
            website Terms and Conditions of Use, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this site. The
            materials contained in this website are protected by applicable
            copyright and trademark law.
          </p>

          <p className="title">Your obligations</p>
          <p className="description">
            You must comply with any instructions we give you about how to use
            our website and must not do anything that interferes with or
            adversely affects the normal operation of the website(including the
            ability of other users to access or use the website). You are
            responsible for ensuring the security of the systems and devices
            that you use to access our website, including through use of
            appropriate virus-checking and other security software.
          </p>

          <p className="title">Registration</p>
          <p className="description">
            You must comply with any instructions we give you about how to use
            our website and must not do anything that interferes with or
            adversely affects the normal operation of the website (including the
            ability of other users to access or use the website). You are
            responsible for ensuring the security of the systems and devices
            that you use to access our website, including through use of
            appropriate virus-checking and other security software.
          </p>

          <p className="title">Intellectual property</p>
          <p className="description">
            All of the intellectual property rights in and to our website belong
            to us and our licensors. We may update and change the materials
            available on our website, including by removing materials, at any
            time in our discretion without notice to you. You may download or
            print sections of our website if needed for your own personal use,
            but otherwise you may not reproduce any part of our website without
            our express prior consent. To the extent that our website contains
            functionality that allows you to access or download specific
            materials through our website, your use of that functionality and
            the downloaded materials may be subject to separate terms and
            conditions which you will be asked to read and accept at the
            relevant time.
          </p>

          <p className="title">Website Links</p>
          <p className="description">
            Our website may contain or link to information that has not been
            devised, verified or tested by us or any of our officers, employees
            or agents. These are provided for your information only. We do not
            take any responsibility for that information, nor endorse its
            accuracy or completeness. We do not guarantee that the information,
            or the provision of the hyperlinks to you, does not infringe third
            party rights. If our website contains a link to an external website,
            we do not endorse, recommend, approve, guarantee or introduce any
            third parties or the services and/or products that those third
            parties may offer. We accept no responsibility for them or any of
            the content available, or for any loss or damage that may arise from
            your use of them. You may provide a link to the home page of our
            website at, on any website you operate, as long as you do not
            suggest any form of association, approval or endorsement on our part
            without our express prior permission and you promptly delete the
            link at our request. Except as set out in this clause, you may not
            link to our website.
          </p>

          <p className="title">Liability</p>
          <p className="description">
            While we will use reasonable care and skill in operating our
            website, we cannot promise that our website will always be
            available, meet your requirements or completely free of faults,
            errors or compromise from cybersecurity events. To the maximum
            extent permitted by law, except as expressly set out in these terms
            of use, we exclude: -all conditions, representations, warranties and
            statutory guarantees, whether express or implied, in relation to our
            website; and -any liability, whether in contract, tort (including
            negligence), or otherwise, for any indirect or consequential loss,
            damage or expense incurred by you or any other user in connection
            with our website. To the extent we are unable by law to exclude any
            implied condition, representation, warranty or statutory guarantee
            in relation to the provision of goods or services through our
            website, we limit our liability for a breach to the re-supply of
            those goods or services, or payment for such re-supply. This is
            without limitation to any other exclusions or restrictions of our or
            others’ liability in connection with this website.
          </p>

          <p className="title">Privacy</p>
          <p className="description">
            We will manage any personal information that we collect through our
            website in accordance with our Privacy Policy, which you can access
            <a href="https://floxus.co/" style={{ textDecoration: 'none' }}>
              {' '}
              here.
            </a>
          </p>
        </TermContainer>
      </TermWrapper>
      <Footer />
    </>
  );
};

export default Term;
