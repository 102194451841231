import React from 'react';
import styled from '@emotion/styled';
import CourseForm from '../CourseForm/CourseForm';
import backImg from '../../images/heroBack2.png';

const ContactWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 50vh;
  justify-content: space-around;
  align-items: center;
  background-color: #6a1b1a;
  background-image: url(${backImg});

  background-position: right;
  background-repeat: no-repeat;
  background-size: 600px;
  padding-top: 20px;

  @media (max-width: 496px) {
    height: auto;
    background-image: none;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  width: 80%;
  min-height: 50vh;
  padding: 20px 0px;

  @media (max-width: 496px) {
    flex-direction: column;
    height: auto;
  }

  @media (max-width: 950px) {
    flex-wrap: wrap;
  }
`;

const ContactLeft = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;

  .heading {
    font-size: 48px;
    font-weight: 600;
    margin-top: 20px;
    color: white;
  }

  .description {
    font-size: 17px;
    font-weight: 400;
    margin-top: 15px;
    padding-right: 8%;
    color: white;
  }

  @media (max-width: 496px) {
    margin-bottom: 20px;
    .description {
      padding-right: 0%;
    }
  }
`;

const ContactRight = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 496px) {
    margin-bottom: 30px;
    margin-top: 20px;
  }
`;

const ContactUs = () => {
  return (
    <>
      <ContactWrapper id="contact">
        <ContactContainer>
          <ContactLeft>
            <p className="heading">Why Us?</p>
            <p className="description">
              Learning coding without projects is like learning a language by
              simply memorizing what each word means in that language. There is
              more to language than just words- conjugations and tenses form the
              core of any language. In a similar way, it is important to do live
              projects while learning to code as it will allow us to put
              everything together in an efficient and effective way to create
              programs. We at MIDHA understand this and hence, our focus remains
              on mastery of material through application.
            </p>
          </ContactLeft>
          <ContactRight>
            <CourseForm title="Contact Us" />
          </ContactRight>
        </ContactContainer>
      </ContactWrapper>
    </>
  );
};

export default ContactUs;
