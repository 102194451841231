import React from 'react';
import styled from '@emotion/styled';
import Toolbar from '../../components/ToolBar/Toolbar';
import Footer from '../../components/Footer/Footer';

const AboutWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
`;

const AboutContainer = styled.div`
  display: flex;
  width: 60%;
  height: auto;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 5%;

  .heading {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .description {
    margin-bottom: 35px;
  }

  p {
    margin-bottom: 20px;
    font-size: 15px;
  }

  @media (max-width: 496px) {
    width: 80%;
    margin-top: 10%;
  }
`;

const About = () => {
  return (
    <>
      <Toolbar />
      <AboutWrapper>
        <AboutContainer>
          <p className="heading">About Us</p>
          <p className="description">
            MidhaPro is a venture of the Midha Group which is known for
            providing high quality of educational content for a large variety of
            courses. We have recognized that in India while many computer
            science graduates are unemployed, there is a large number of
            unfilled job opportunities in the technology and data sector. This
            situation arises due to the gap between student knowledge and the
            required technical know-how required for the various jobs. MidhaPro
            aims to bridge this gap by providing high quality education to
            students at a reasonable price. We not only focus on the theoretical
            understanding but also on the practical applicability of the various
            skills we teach.{' '}
          </p>
        </AboutContainer>
      </AboutWrapper>
      <Footer />
    </>
  );
};

export default About;
