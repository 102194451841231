import React from 'react';
import styled from '@emotion/styled';
import * as AiIcons from 'react-icons/ai';
import * as SiIcons from 'react-icons/si';
import LiveLogo from '../../images/live2.png';
import CertiLogo from '../../images/certi2.png';
import ProLogo from '../../images/pro2.png';

const MidhaUSPContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-bottom: 10px solid #d6a468;

  @media (max-width: 496px) {
    ${'' /* margin-top: 20px; */}
  }
`;

const MidhaUSPWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 90%;
  margin-bottom: 5%;
  margin-top: 5%;

  @media (max-width: 1280px) {
    width: 90%;
  }

  @media (max-width: 1024px) {
    width: 95%;
  }

  @media (max-width: 496px) {
    margin-top: 10%;
  }
`;

const MidhaUSPHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  align-items: center;

  .heading {
    font-size: 32px;
    font-weight: 600;
    ${'' /* width: 500px; */}
    text-align: center;
    margin-bottom: 10px;
    ${'' /* color: #6a1b1a; */}
  }

  .description {
    width: 70%;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    font-size: 18px;
  }

  @media (max-width: 496px) {
    .heading {
      font-size: 18px;
      width: 90%;
    }

    .description {
      font-size: 12px;
      width: 90%;
      text-align: center;
      margin-top: 10px;
    }
  }
`;

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 450px;
  margin-top: 25px;
  margin-bottom: 20px;
  align-items: center;
  border: 1px solid #6a1b1a;
  border-radius: 37px;
  padding: 30px 20px 20px 20px;
  transition: box-shadow 0.2s;
  margin-right: 28px;

  &:hover {
    box-shadow: -1px 0px 80px -68px rgba(0, 0, 0, 0.75);
    transition: box-shadow 0.4s;
  }

  .top {
    display: flex;
    height: 40%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  img {
    ${'' /* margin-bottom: 10px; */}
  }

  .icon {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${'' /* background-color: rgba(210, 155, 89, 0.4); */}
    background-color: white;
    border-radius: 5px;
  }

  .heading {
    font-size: 25px;
    font-weight: 600;
    ${'' /* margin-top: 15px; */}
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  .heading1 {
    font-size: 25px;
    font-weight: 600;
    ${'' /* margin-top: 15px; */}
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    margin-top: -7px;
    ${'' /* margin-bottom: 3px; */}
  }

  .description {
    font-size: 18px;
    font-weight: 400;
    margin-top: 15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 500px) {
    padding: 20px 20px 20px 20px;
    .description {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1280px) {
    width: 250px;
    height: 380px;
    margin-right: 15px;

    .heading {
      font-size: 23px;
    }

    .heading1 {
      font-size: 23px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      margin-top: 10px;
      text-align: center;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  ${
    '' /* @media screen and (max-width: 1024px) {
    width: 250px;
    height: 380px;
    margin-right: 15px;

    .description {
      font-size: 16px;
      font-weight: 400;
      margin-top: 10px;
      text-align: center;
      color: rgba(0, 0, 0, 0.5);
    }
  } */
  }
`;

const MidhaUSP = () => {
  return (
    <>
      <MidhaUSPContainer>
        <MidhaUSPWrapper>
          <MidhaUSPHeader>
            <p className="heading">
              MidhaPro focuses on “Foundation, Applicability and Employability”
            </p>
            <p className="description">
              In tech and data, there is no lack of jobs, there is a lack of
              skills! Become employable with MidhaPro and be part of one of the
              fastest growing industry!
            </p>
          </MidhaUSPHeader>
          <FeatureContainer>
            <FeatureCard>
              <div>
                <img src={CertiLogo} alt="certi logo" />
              </div>
              <p className="heading1">Certification</p>
              <p className="description">
                International Corporate Certifications from companies like
                Microsoft to make your resume attractive. Why write about a
                certificate in python when you can write about a Microsoft
                Certificate in Python!
              </p>
            </FeatureCard>
            <FeatureCard>
              {' '}
              {/* <SiIcons.SiGoogleclassroom
                  style={{ fontSize: '48px', color: 'maroon' }}
                />{' '} */}
              <img src={LiveLogo} />
              <p className="heading">Live Classes</p>
              <p className="description">
                Live interactive classes to ensure Every student understands
                Every concept! Is coding difficult? Yes! But our instructors
                have guided hundreds of students through the process of
                developing this valuable skill.
              </p>
            </FeatureCard>
            <FeatureCard>
              {' '}
              {/* <AiIcons.AiOutlineFundProjectionScreen
                  style={{ fontSize: '48px', color: 'maroon' }}
                />{' '} */}
              <img src={ProLogo} />
              <p className="heading">Project Based Learning</p>
              <p className="description">
                Faculty guides every student to build various projects that not
                only help get a deep understanding of the concepts, but also,
                allows for additions to the resume. Why only write about knowing
                python when you can write about the 5 projects you made in that
                language!
              </p>
            </FeatureCard>
          </FeatureContainer>
        </MidhaUSPWrapper>
      </MidhaUSPContainer>
    </>
  );
};

export default MidhaUSP;
